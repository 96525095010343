<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <br>
      <v-card
        class="my-vcard-style"
      >
        <v-container class="py-0">
          <v-row
            class="fill-height flex-column"
            align="center"
            justify="center"
          >
            <v-col cols="12">
              <v-icon
                size="80"
                color="#2E7D32"
              >
                mdi-check-circle
              </v-icon>
              <p class="title-text">
                Thank You For Your Purchase!
              </p>
            </v-col>
            <v-col cols="12">
              <p class="subtitle-text">
                Thanks for being awesome,
                <br>We hope you enjoy your purchase.
              </p>
            </v-col>
            <v-col
              v-if="isPurchaseDetailsAvailable"
              cols="12"
            >
              <p
                class="alert-text"
              >
                Your next renew date will be on {{ purchaseDetails.data.current_period_end | convertToLocal }}
              </p>
            </v-col>
            <v-col cols="12">
              <p class="message-text">
                If you have any questions or concerns regarding this, do not hesitate to contact us at <span class="info-text">info@dbsystems.com.au</span>
              </p>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="#FF3700"
                @click="$router.push({name: 'Dashboard'})"
              >
                <v-icon left>
                  mdi-home
                </v-icon>
                Home
              </v-btn>
              <v-btn
                color="#37474F"
                @click="downloadInvoice()"
              >
                <v-icon
                  left
                >
                  mdi-file-download
                </v-icon>
                Download Invoice
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <br>
      <br>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import Constants from 'src/constants';
import moment from 'moment';
import global from 'src/mixins/global';

export default {
  name: 'CheckoutSuccess',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
  },
  mixins: [global],
  data () {
    return {
      loading: false,
    };
  },
  computed: {
    purchaseDetails () {
      return this.$store.getters['payments/getPurchaseDetails'];
    },
    invoiceUrls () {
      return this.$store.getters['payments/getInvoiceDownloadUrl'];
    },
    isPurchaseDetailsAvailable () {
      if (Object.keys(this.purchaseDetails).length > 0) {
        return true;
      }
      return false;
    },
  },
  async mounted () {
    await this.makeCheckoutSessionComplete();
  },
  methods: {
    async makeCheckoutSessionComplete () {
      this.loading = true;
      const clientReferenceId = this.$route.params.id;
      await this.$store.dispatch('payments/checkoutSessionCompleted', {
        status: Constants.CHECKOUT_SESSION_SUCCESS,
        client_reference_id: clientReferenceId,
      }).then(response => {
        this.loading = false;
      }).catch(() => {
        this.$router.push({ name: 'Dashboard' });
      });
    },
    async downloadInvoice () {
      if (this.permissionCheck('download-invoice') === false) {
        this.$store.dispatch('alert/onAlert', {
          message: 'You are not authorized to perform this action. Please contact your administrator.',
          type: Constants.ALERT_TYPE_INFO,
        });
        return;
      }
      this.loading = true;
      await this.$store.dispatch('payments/fetchInvoiceDownloadUrl', {
        subscriptionId: this.purchaseDetails.data.id,
        purchaseMode: this.purchaseDetails.data.mode,
      }).then(response => {
        this.loading = false;
        window.open(this.invoiceUrls.download_url, '_blank');
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 25px;
  text-align: center;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
  text-align: center;
}
.title-text {
  color: #37474F;
  font-size: 40px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.subtitle-text {
  font-size: 25px;
  color: #546E7A;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.alert-text {
  font-size: 20px;
  color: #37474F;
  background-color: #CFD8DC;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
}
.message-text {
  font-size: 18px;
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.info-text {
  color: #FF3700;
}
</style>
